import { useEffect } from 'react';
import { SessionProvider as AuthProvider, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { ChakraProvider, Flex, Spinner, useColorModeValue } from '@chakra-ui/react';

// Theme
import themeExtension from '../components/Theme';

// Use the <Provider> to improve performance and allow components that call
// `useSession()` anywhere in your application to access the `session` object.
export default function App( { Component, pageProps } ) {
	return (
		<AuthProvider
			refetchInterval={ 60 * 10 } // Refetch session every 10 minutes.
			session={ pageProps.session }
		>
			<ChakraProvider theme={ themeExtension }>
				<Head>
					<title>Kaufleuten Scanner</title>
					<meta
						name="viewport"
						content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
					/>
					<meta key="theme-color" name="theme-color" content="#f7fafc" />
				</Head>
				{ Component.auth ? (
					<Auth>
						<Component { ...pageProps } />
					</Auth>
				) : (
					<Component { ...pageProps } />
				) }
			</ChakraProvider>
		</AuthProvider>
	);
}

function Auth( { children } ) {
	const { data: session, status } = useSession();
	const loading = status === 'loading';
	const router = useRouter();

	const isUser = !! session?.user;

	useEffect( () => {
		// Do nothing while loading
		if ( loading ) {
			return;
		}

		// If not authenticated, force log in.
		if ( ! isUser ) {
			router.push( {
				pathname: '/auth/signin',
				query: {
					redirect: router.asPath,
				},
			} );
		}
	}, [ router, isUser, loading ] );

	const bgColor = useColorModeValue( 'gray.50', 'inherit' );

	// We have a user.
	if ( isUser ) {
		return children;
	}

	// Session is being fetched.
	return (
		<Flex bg={ bgColor } minH="100vh" direction="column" justify="center" align="center">
			<Spinner
				thickness="4px"
				speed="0.65s"
				emptyColor="gray.200"
				color="blue.500"
				size="xl"
			/>
		</Flex>
	);
}
