import { extendTheme, ThemeConfig } from '@chakra-ui/react';

const config: ThemeConfig = {
	initialColorMode: 'light',
};

const colors = {
	brand: {
		'50': '#fdfcfa',
		'100': '#fbf1ec',
		'200': '#f6d3d6',
		'300': '#eaa7af',
		'400': '#e47783',
		'500': '#d4535f',
		'600': '#b93942',
		'700': '#912a30',
		'800': '#661d20',
		'900': '#3d1212',
	},
};

const components = {
	/*Modal: {
		baseStyle: {
			dialogContainer: {
				// Fix fullscreen modal height on iOS 15: https://github.com/chakra-ui/chakra-ui/issues/4680
				'@supports(height: -webkit-fill-available)': {},
			},
		},
	},*/
};

const styles = {
	global: () => ( {
		// Fix missing modal footer on iOS 15.
		'.chakra-modal__content': {
			'@supports(height: -webkit-fill-available)': {
				minHeight: '-webkit-fill-available !important',
			},
		},
	} ),
};

const themeExtension = extendTheme( { config, colors, styles, components } );

export default themeExtension;
